import React, { useState, useRef } from "react";
import { Card, Collapse, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeButton from "../components/themeButton";
import ViewTableSchemaModal from "../modals/viewTableSchema.jsx";
import CreateDataTableModal from "../modals/createDataTableModal.jsx";

const TableSchemaCards = ({ tableSchemas, userRole, loadDataTablesCallback }) => {
  const [openCardId, setOpenCardId] = useState(null);
  const scrollContainerRef = useRef(null);

  const [viewTableSchemaModalShow, setViewTableSchemaModalShow] = useState(false);
  const [selectedTableSchemaModalId, setSelectedTableSchemaModalId] = useState(null);

  const [createDataTableModalShow, setCreateDataTableModalShow] = useState(false);
  const [selectedCreateDataTableModalSchemaId, setSelectedCreateDataTableModalSchemaId] = useState(null);
  const [createDataTableType, setCreateDataTableType] = useState(null);

  const toggleCard = (cardId) => {
    setOpenCardId(openCardId === cardId ? null : cardId);
  };

  const handleCreateDataTable = (schema) => {
    // Placeholder for create data table functionality
    setSelectedCreateDataTableModalSchemaId(schema.id);
    setCreateDataTableModalShow(true);
    setCreateDataTableType("data_table");
  };

  const handleCreateTestingTable = (schema) => {
    // Placeholder for create testing table functionality
    setSelectedCreateDataTableModalSchemaId(schema.id);
    setCreateDataTableModalShow(true);
    setCreateDataTableType("test_table");
  };

  const handleViewSchema = (schema) => {
    // Placeholder for view schema functionality
    setSelectedTableSchemaModalId(schema.id);
    setViewTableSchemaModalShow(true);
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollContainerStyle = {
    overflowX: "auto",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    padding: "20px"
  };

  const scrollButtonsStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  const buttonStyle = {
    margin: "0 5px",
    color: "#fff",
  };

  const cardStyle = {
    cursor: "pointer",
    transition: "transform 0.2s",
    backgroundColor: "#fff",
    borderColor: "#50011a",
    color: "#50011a",
    borderWidth: "2px",
    borderStyle: "solid",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    minWidth: "300px", // Ensure cards have a minimum width
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  };

  const cardTitleStyle = {
    color: "#50011a",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const themeButtonStyle = {
    marginRight: "5px",
    height: "50px",
    fontSize: "12px",
  };

  return (
    <Container className="mt-4" style={{ overflowY: "auto" }}>
      <div style={scrollContainerStyle} ref={scrollContainerRef}>
        <Row className="flex-nowrap">
          {tableSchemas && tableSchemas.map((schema) => (
            <Col key={schema.id} md={4} className="mb-4">
              <Card onClick={() => toggleCard(schema.id)}
                style={{
                  ...cardStyle, transform: openCardId === schema.id ? "scale(1.05)" : "scale(1)",
                }}>
                <Card.Body>
                  <Card.Title style={cardTitleStyle}>{schema.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Created by: {schema.created_by_name}
                  </Card.Subtitle>
                  <Card.Text>{schema.description || "No description provided"}</Card.Text>

                  <Collapse in={openCardId === schema.id}>
                    <div>
                      <hr />
                      <div className="d-flex justify-content-between overflow-auto">
                        <ThemeButton
                          variant="outline-primary"
                          style={themeButtonStyle}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateDataTable(schema);
                          }}
                          disabled={userRole !== "admin"}
                        >
                          Create Data Table
                        </ThemeButton>
                        <ThemeButton
                          variant="outline-primary"
                          style={themeButtonStyle}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateTestingTable(schema);
                          }}
                          disabled={userRole !== "admin"}
                        >
                          Create Testing Table
                        </ThemeButton>
                        <ThemeButton
                          variant="outline-primary"
                          style={themeButtonStyle}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewSchema(schema);
                          }}
                        >
                          View Schema
                        </ThemeButton>
                      </div>
                      <div className="mt-2 text-muted small">
                        <p>Last Updated: {new Date(schema.last_updated).toLocaleString()}</p>
                        <p>Created By: {schema.created_by_email}</p>
                      </div>
                    </div>
                  </Collapse>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div style={scrollButtonsStyle}>
        <ThemeButton variant="outline-primary" onClick={scrollLeft} style={buttonStyle}>
        </ThemeButton>
        <ThemeButton variant="outline-primary" onClick={scrollRight} style={buttonStyle}>
        </ThemeButton>
      </div>

      <ViewTableSchemaModal
        show={viewTableSchemaModalShow}
        onHide={() => {
          setViewTableSchemaModalShow(false);
        }}
        tableSchemaId={selectedTableSchemaModalId}
      />

      <CreateDataTableModal
        show={createDataTableModalShow}
        onHide={() => {
          setCreateDataTableModalShow(false);
        }}
        tableSchemaId={selectedCreateDataTableModalSchemaId}
        tableType={createDataTableType}
        loadDataTablesCallback={loadDataTablesCallback}
      />
    </Container>
  );
};

export default TableSchemaCards;
