import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { createDataTable } from "../apis/dataApi";
import { getThisUser } from "../apis/userApis";
import { FaAngleUp } from "react-icons/fa6";
import ThemeButton from "../components/themeButton";

const CreateDataTableModal = ({ show, onHide, tableSchemaId, tableType, loadDataTablesCallback }) => {
  const [uploading, setUploading] = useState(false);
  const [profile, setProfile] = useState({});
  const [tableName, setTableName] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const getTableTypeDescription = () => {
    switch (tableType) {
      case "data_table":
        return "A standard table for storing structured data with consistent schema. This table CANNOT be deleted.";
      case "test_table":
        return "A specialized table used for testing and temporary data storage. Table will be deleted after 1 day.";
      default:
        return "Unspecified table type.";
    }
  };

  const fetchUserProfile = (email) => {
    console.log("Fetching profile for: " + email);
    getThisUser(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred while fetching user profile: " + error);
        resetState();
        onHide();
      });
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        window.addEventListener("resize", handleResize);
      } else {
        onHide();
      }
    }
  }, [show, onHide]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  const handleCreateTable = async () => {
    if (!tableName) {
      window.alert("Please enter a table name.");
      return;
    }

    setUploading(true);

    createDataTable(tableSchemaId, tableName, tableType)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUploading(false);
          window.alert("Successfully created SQL Data Table!");
          loadDataTablesCallback();
          resetState();
          onHide();
        } else {
          console.log(response);
          setUploading(false);
          window.alert("Failed to create Data Table. Error: " + response.status + "\nDetails: " + response.data.message);
        }
      })
      .catch((error) => {
        window.alert("An error occurred while creating the table: " + error);
        setUploading(false);
      });
  };

  const resetState = () => {
    setTableName("");
    setUploading(false);
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Data Table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {uploading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spinner animation="border" role="status" style={{ marginRight: "10px" }}>
            </Spinner>
            <span>Creating SQL Data Table...</span>
          </div>
        ) : (
          <div>
            <p style={{ marginBottom: "15px", fontSize: isMobile ? "14px" : "inherit" }}>
              <strong>Owner:</strong> {profile.email}
            </p>

            <div className="mb-3">
              <label htmlFor="tableName" className="form-label">
                <strong>Table Name</strong>
              </label>
              <input
                type="text"
                className="form-control"
                id="tableName"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
                placeholder="Enter table name"
                style={{ fontSize: isMobile ? "14px" : "inherit" }}
              />
            </div>

            <div className="mb-3">
              <strong>Table Type: </strong>
              {tableType &&
                <span style={{ textTransform: "capitalize" }}>
                  {tableType.replace("_", " ")}
                </span>
              }

              <p
                className="text-muted"
                style={{
                  marginTop: "5px",
                  fontSize: isMobile ? "12px" : "0.875rem"
                }}
              >
                {getTableTypeDescription()}
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton
          variant="outline-primary"
          onClick={handleCreateTable}
          disabled={uploading || !tableName}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaAngleUp />
            Create Table
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDataTableModal;