import React from "react";
import { Button } from "react-bootstrap";

const ThemeButton =
  (props) => {
    return (
      <Button
        style={{
          ...props.style,
          "--bs-btn-color": "#50011a",
          "--bs-btn-border-color": "#50011a",
          "--bs-btn-hover-color": "#fff",
          "--bs-btn-hover-bg": "#50011a",
          "--bs-btn-hover-border-color": "#50011a",
          "--bs-btn-focus-shadow-rgb": "33,37,41",
          "--bs-btn-active-color": "#fff",
          "--bs-btn-active-bg": "#50011a",
          "--bs-btn-active-border-color": "#50011a",
          "--bs-btn-disabled-color": "#50011a",
          "--bs-btn-disabled-border-color": "#50011a",

        }}
        variant={props.variant}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    );
  };
export default ThemeButton;