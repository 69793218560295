const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Retrieves all table schemas from the server.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getAllTableSchemas = async () => {
  try {
    const response = await fetch(`${baseURL}/api/data/getAllTableSchemas`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves a table schema by its ID from the server.
 *
 * @param {string} schemaId - The ID of the table schema to retrieve.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getTableSchemaById = async (schemaId) => {
  try {
    const response = await fetch(`${baseURL}/api/data/getTableSchemaById?schemaId=${schemaId}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a new table schema to the server.
 *
 * @param {Object} tableSchema - The schema object to be added.
 * @param {string} name - The name of the table schema.
 * @param {string} description - The description of the table schema.
 * @return {Promise<Object>} A promise that resolves to an object containing the status code and data if successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const addTableSchema = async (tableSchema, name, description) => {
  try {
    const response = await fetch(`${baseURL}/api/data/addTableSchema`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description,
        tableSchema
      }),
    });
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Deletes a table schema by its ID from the server.
 *
 * @param {string} schemaId - The ID of the table schema to delete.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const deleteTableSchema = async (schemaId) => {
  try {
    const response = await fetch(`${baseURL}/api/data/deleteTableSchema?schemaId=${schemaId}`,
      { method: "DELETE", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Updates a table schema by its ID on the server.
 *
 * @param {string} schemaId - The ID of the table schema to update.
 * @param {Object} tableSchema - The JSON object of the updated table schema.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const updateTableSchema = async (schemaId, tableSchema, name, description) => {
  try {
    const response = await fetch(`${baseURL}/api/data/updateTableSchema?schemaId=${schemaId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description,
        tableSchema
      }),
    });
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all data tables from the server.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getAllDataTables = async () => {
  try {
    const response = await fetch(`${baseURL}/api/data/getAllDataTables`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Creates a data table from a given table schema.
 *
 * @param {string} schemaID - The ID of the table schema.
 * @param {string} tableName - The name of the new table.
 * @param {string} tableType - The type of table to create (e.g. "data_table" or "view").
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const createDataTable = async (schemaID, tableName, tableType) => {
  try {
    const response = await fetch(`${baseURL}/api/data/createDataTable?schemaId=${schemaID}&tableType=${tableType}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tableName
        })
      }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Recommends a data table schema based on a given file and file metadata.
 *
 * @param {Object} file - The file to use for generating the table schema.
 * @param {Object} fileMetaData - The file metadata to use for generating the table schema.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const recommendDataTableSchema = async (file, fileMetaData) => {
  const fileMetaDataJSON = {
    name: fileMetaData.name,
    type: fileMetaData.type,
    size: fileMetaData.size,
    lastModified: fileMetaData.lastModified,
    lastModifiedDate: fileMetaData.lastModifiedDate,
  };

  // const fileArrayBuffer = await file.arrayBuffer();
  console.log("file", file);
  try {
    const response = await fetch(`${baseURL}/api/data/recommendDataTableSchema`,
      {
        method: "POST", credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ metaData: fileMetaDataJSON, file: Array.from(new Uint8Array(file)) }),
      },
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Parses a given file and its associated metadata for SQL data ingestion.
 *
 * @param {Object} file - The file to parse.
 * @param {Object} fileMetaData - The metadata of the file to parse.
 * @param {string} tableName - The name of the table to ingest the data into.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const parseFileForSQLDataIngestion = async (file, fileMetaData, tableName) => {
  const fileMetaDataJSON = {
    name: fileMetaData.name,
    type: fileMetaData.type,
    size: fileMetaData.size,
    lastModified: fileMetaData.lastModified,
    lastModifiedDate: fileMetaData.lastModifiedDate,
  };
  try {
    const response = await fetch(`${baseURL}/api/data/parseFileForSQLDataIngestion?tableName=${tableName}`,
      {
        method: "POST", credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ metaData: fileMetaDataJSON, file: Array.from(new Uint8Array(file)) }),
      },
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const getDataFromTable = async (tableName, startIndex, numberOfRows) => {

  let urlQuery = `${baseURL}/api/data/getDataFromTable?tableName=${tableName}`;
  if (startIndex && numberOfRows) {
    urlQuery += `&startIndex=${startIndex}&numberOfRows=${numberOfRows}`;
  }

  try {
    const response = await fetch(urlQuery,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const postSQLIngestionQuery = async (queries, tableName) => {
  try {
    const response = await fetch(`${baseURL}/api/data/postSQLIngestionQuery?tableName=${tableName}`,
      {
        method: "POST", credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ SQLQueries: queries }),
      },
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};