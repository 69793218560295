import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { FaTrash, FaPlus, FaRegSquarePlus } from "react-icons/fa6";
import { addTableSchema, recommendDataTableSchema } from "../apis/dataApi";
import ThemeButton from "../components/themeButton";

const globalContstant = {
  datascribeConstants: {
    "SUPPORTED_SQL_DATA_TYPES": [
      "varchar", "char", "decimal", "numeric", "bigint", "smallint", "integer", "real", "double precision", "text", "date", "time", "timestamp", "boolean"
    ]
  }
};

// Validation utility functions
const validateTypeOptions = {
  varchar: (length) => length >= 1 && length <= 65535,
  char: (length) => length >= 1 && length <= 255,
  decimal: (precision, scale) =>
    precision >= 1 && precision <= 65 &&
    scale >= 0 && scale <= precision,
  numeric: (precision, scale) =>
    precision >= 1 && precision <= 65 &&
    scale >= 0 && scale <= precision
};

const validateDefaultValue = {
  "varchar": (value, length) =>
    typeof value === "string" && value.length <= length,
  "char": (value, length) =>
    typeof value === "string" && value.length <= length,
  "decimal": (value, precision, scale) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return false;

    // Check total number of digits
    const stringValue = Math.abs(numValue).toString().replace(".", "");
    return stringValue.length <= precision &&
      (value.includes(".") ?
        value.split(".")[1].length <= scale :
        true);
  },
  "numeric": (value, precision, scale) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return false;

    // Check total number of digits
    const stringValue = Math.abs(numValue).toString().replace(".", "");
    return stringValue.length <= precision &&
      (value.includes(".") ?
        value.split(".")[1].length <= scale :
        true);
  },
  "int": (value) => {
    const numValue = parseInt(value);
    return !isNaN(numValue) && Number.isInteger(numValue);
  },
  "bigint": (value) => {
    const numValue = parseInt(value);
    return !isNaN(numValue) && Number.isInteger(numValue);
  },
  "date": (value) => {
    return value && !isNaN(Date.parse(value));
  },
  "timestamp": (value) => {
    return value && !isNaN(Date.parse(value));
  }
};

const AddTableSchemaModal = ({ show, onHide, setAddTableSchemaResponse }) => {
  const [tableSchema, setTableSchema] = useState({
    table_name: "",
    description: "",
    columns: [
      {
        column_name: "",
        column_type: "",
        type_options: {},
        primary_key: false,
        nullable: true,
        default_value: null,
        metadata: {}
      }
    ]
  });

  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [metadataColumns, setMetadataColumns] = useState(new Set());

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileMetadata, setSelectedFileMetadata] = useState(null);


  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setAddTableSchemaResponse(200);
        window.addEventListener("resize", handleResize);
      } else {
        setAddTableSchemaResponse(401);
        onHide();
      }
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [show, onHide]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  const handleTableNameChange = (e) => {
    setTableSchema({ ...tableSchema, table_name: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setTableSchema({ ...tableSchema, description: e.target.value });
  };

  const validateColumn = (column) => {
    const errors = {};

    // Validate type options
    if (column.column_type === "varchar" || column.column_type === "char") {
      if (!validateTypeOptions[column.column_type](column.type_options.length)) {
        errors.length = `Invalid length for ${column.column_type}`;
      }
    }

    if (column.column_type === "decimal" || column.column_type === "numeric") {
      if (!validateTypeOptions[column.column_type](
        column.type_options.precision,
        column.type_options.scale
      )) {
        errors.precision = `Invalid precision or scale for ${column.column_type}`;
      }
    }

    // Validate default value if not nullable
    if (!column.nullable && column.default_value !== null && column.column_type) {
      let isValidDefault = false;
      switch (column.column_type) {
        case "varchar":
        case "char":
          isValidDefault = validateDefaultValue[column.column_type](
            column.default_value,
            column.type_options.length
          );
          break;
        case "decimal":
        case "numeric":
          isValidDefault = validateDefaultValue[column.column_type](
            column.default_value,
            column.type_options.precision,
            column.type_options.scale
          );
          break;
        default:
          isValidDefault = validateDefaultValue[column.column_type](column.default_value);
      }

      if (!isValidDefault) {
        errors.default_value = `Invalid default value for ${column.column_type}`;
      }
    }

    return errors;
  };

  const handleColumnChange = (index, field, value) => {
    const columns = [...tableSchema.columns];
    const updatedErrors = { ...validationErrors };

    if (field === "nullable") {
      columns[index] = {
        ...columns[index],
        nullable: value,
        // Reset default_value if column becomes nullable
        default_value: value ? null : columns[index].default_value
      };
      delete updatedErrors[index]?.default_value;
    }
    // Reset type_options when type changes
    else if (field === "column_type") {
      const typeOptionsMap = {
        "varchar": { length: 1 },
        "char": { length: 1 },
        "decimal": { precision: 10, scale: 2 },
        "numeric": { precision: 10, scale: 2 }
      };

      columns[index] = {
        ...columns[index],
        column_type: value,
        type_options: typeOptionsMap[value] || {},
        default_value: null // Reset default value when type changes
      };
      // Clear any previous type-specific validation errors
      delete updatedErrors[index];
    }
    // Handle type options with validation
    else if (field === "type_options") {
      columns[index] = {
        ...columns[index],
        type_options: value
      };

      // Validate type options
      const typeSpecificErrors = validateColumn(columns[index], index);
      if (Object.keys(typeSpecificErrors).length > 0) {
        updatedErrors[index] = typeSpecificErrors;
      } else {
        delete updatedErrors[index];
      }
    }
    // Handle default value with validation
    else if (field === "default_value") {
      columns[index] = {
        ...columns[index],
        default_value: value
      };

      // Validate default value if not nullable
      if (!columns[index].nullable) {
        const defaultValueErrors = validateColumn(columns[index], index);
        if (Object.keys(defaultValueErrors).length > 0) {
          updatedErrors[index] = defaultValueErrors;
        } else {
          delete updatedErrors[index];
        }
      }
    }
    // Handle metadata changes
    else if (field === "metadata") {
      columns[index] = {
        ...columns[index],
        metadata: value
      };
    }
    // Handle other fields
    else {
      columns[index][field] = value;
    }


    // console.log(columns);
    setTableSchema({ ...tableSchema, columns });
    setValidationErrors(updatedErrors);
  };

  const addColumn = () => {
    setTableSchema({
      ...tableSchema,
      columns: [
        ...tableSchema.columns,
        {
          column_name: "",
          column_type: "",
          type_options: {},
          primary_key: false,
          nullable: true,
          default_value: null,
          metadata: {}
        }
      ]
    });
  };

  const removeColumn = (index) => {
    const columns = [...tableSchema.columns];
    columns.splice(index, 1);
    setTableSchema({ ...tableSchema, columns });
  };
  const handleSubmit = async () => {
    // Validate all columns before submission
    const submissionErrors = {};
    tableSchema.columns.forEach((column, index) => {
      const columnErrors = validateColumn(column);
      if (Object.keys(columnErrors).length > 0) {
        submissionErrors[index] = columnErrors;
      }
    });

    // If any validation errors, prevent submission
    if (Object.keys(submissionErrors).length > 0) {
      const readableErrors = Object.entries(submissionErrors)
        .map(([index, errors]) =>
          Object.entries(errors).map(([field, message]) => `Column ${index + 1} ${field}: ${message}`)
        )
        .flat()
        .join("\n");
      alert(`Please correct the following validation errors before submitting:\n${readableErrors}`);
      return;
    }

    setIsLoading(true);
    const response = await addTableSchema(
      tableSchema,
      tableSchema.table_name,
      tableSchema.description
    );
    if (response.status >= 200 && response.status < 300) {
      resetState();
      onHide();
      setAddTableSchemaResponse(200);
    } else {
      setAddTableSchemaResponse(response.status);
      alert("Error: Bad request. Please check your input and try again.\nMessage from server:" + response.data.message);
    }
    setIsLoading(false);
  };

  const resetState = () => {
    setTableSchema({
      table_name: "",
      description: "",
      columns: [
        {
          column_name: "",
          column_type: "",
          type_options: {},
          primary_key: false,
          nullable: true,
          default_value: null,
          metadata: {}
        }
      ]
    });
    setValidationErrors({});
    setAddTableSchemaResponse(null);
    setIsLoading(false);
    setValidationErrors({});
    setMetadataColumns(new Set());
    setProfile(null);
    setSelectedFile(null);
    setFileName(null);
  };

  const handleClose = () => {
    resetState();
    onHide();
  };

  const renderTypeOptions = (column, index) => {
    switch (column.column_type) {
      case "varchar":
      case "char":
        return (
          <Col>
            <Form.Group>
              <Form.Label>Length</Form.Label>
              <Form.Control
                type="number"
                min={1}
                value={column.type_options.length || 0}
                onChange={(e) =>
                  handleColumnChange(index, "type_options", {
                    length: parseInt(e.target.value)
                  })
                }
                required
              />
            </Form.Group>
          </Col>
        );
      case "decimal":
      case "numeric":
        return (
          <>
            <Col>
              <Form.Group>
                <Form.Label>Precision</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  value={column.type_options.precision || 0}
                  onChange={(e) =>
                    handleColumnChange(index, "type_options", {
                      ...column.type_options,
                      precision: parseInt(e.target.value)
                    })
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Scale</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  value={column.type_options.scale || 0}
                  onChange={(e) =>
                    handleColumnChange(index, "type_options", {
                      ...column.type_options,
                      scale: parseInt(e.target.value)
                    })
                  }
                  required
                />
              </Form.Group>
            </Col>
          </>
        );
      default:
        return null;
    }
  };

  const renderDefaultValueInput = (column, index) => {
    // Only show default value input if column is not nullable
    if (column.nullable) return null;

    // If no type is selected, provide a disabled input
    if (column.column_type === null || column.column_type === "") {
      return (
        <Col>
          <Form.Group>
            <Form.Label>Default Value</Form.Label>
            <Form.Control
              type="text"
              value=""
              placeholder="Select a type first"
              disabled
              readOnly
            />
          </Form.Group>
        </Col>
      );
    }

    // Determine input type based on column type
    let inputType = "text";
    let placeholder = "Enter default value";

    switch (column.column_type) {
      case "varchar":
      case "char":
        inputType = "text";
        placeholder = "Enter default string";
        break;
      case "int":
      case "bigint":
        inputType = "number";
        placeholder = "Enter default integer";
        break;
      case "decimal":
      case "numeric":
        inputType = "number";
        placeholder = "Enter default decimal";
        break;
      case "date":
        inputType = "date";
        placeholder = "Select default date";
        break;
      case "timestamp":
        inputType = "datetime-local";
        placeholder = "Select default timestamp";
        break;
    }

    return (
      <Col>
        <Form.Group>
          <Form.Label>Default Value</Form.Label>
          <Form.Control
            type={inputType}
            value={column.default_value || ""}
            onChange={(e) => handleColumnChange(index, "default_value", e.target.value)}
            placeholder={placeholder}
            required={!column.nullable}
          />
        </Form.Group>
      </Col>
    );
  };

  const handleMetadataToggle = (index, checked) => {
    const newMetadataColumns = new Set(metadataColumns);
    if (checked) {
      newMetadataColumns.add(index);
    } else {
      newMetadataColumns.delete(index);
    }
    setMetadataColumns(newMetadataColumns);
  };

  const renderMetadata = (column, index) => {
    return (
      <Col>
        <Form.Group>
          <Form.Label>Units</Form.Label>
          <Form.Control
            type="text"
            value={column.metadata?.units || ""}
            onChange={(e) => handleColumnChange(index, "metadata", { units: e.target.value })}
            placeholder="Enter units (e.g., 'kg', 'm/s', '$')"
          />
        </Form.Group>
      </Col>
    );
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = encodeURIComponent(file.name); // Encode the file name
      setSelectedFileMetadata(file);
      setFileName(fileName);
      const fileChunk = await file.arrayBuffer();
      setSelectedFile(fileChunk);
    }
  };

  const handleGenerateTableSchema = async () => {
    setIsLoading(true);
    // console.log(selectedFile);
    try {
      // console.log(selectedFile, selectedFileMetadata);
      const response = await recommendDataTableSchema(selectedFile, selectedFileMetadata);
      if (response.status === 200) {
        const recommendedTableSchema = response.data;
        console.log(recommendedTableSchema);

        // Identify columns with metadata
        const newMetadataColumns = new Set();
        recommendedTableSchema.columns.forEach((column, index) => {
          if (column.metadata && Object.keys(column.metadata).length > 0) {
            newMetadataColumns.add(index);
          }
        });

        setTableSchema(recommendedTableSchema);
        setMetadataColumns(newMetadataColumns);

        setAddTableSchemaResponse(200);
      } else {
        setAddTableSchemaResponse(response.status);
        alert("Error: Unable to generate table schema. Please try again.\nServer Response: " + response.data.message);
      }
    } catch (err) {
      setAddTableSchemaResponse(500);
      alert("Error: Unable to generate table schema. Please try again.");
    }
    setIsLoading(false);
  };


  return (
    <Modal
      show={show} onHide={handleClose} centered
      dialogClassName="modal-dialog-centered modal-lg"
      className="custom-modal-style">
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="w-100 text-center position-relative">
          Create Table Schema
          {isLoading && (
            <div className="spinner-border spinner-border-sm position-absolute">
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Row className={selectedFile ? "" : "mb-3"}>
            <Col>
              <Form.Label style={{ marginBottom: "0" }}>Upload Sample File to Generate Table Schema </Form.Label>
              <p className="text-muted" style={{ fontSize: "12px", marginBottom: "0" }}> (Max File Size: 5MB)</p>
            </Col>
            <Col>
              {selectedFile ?
                <>
                  <ThemeButton
                    variant="outline-primary" style={{
                      marginLeft: "10px", marginRight: "10px", marginTop: "10px",
                      fontSize: isMobile ? "14px" : ""
                    }} onClick={handleGenerateTableSchema}>
                    Generate Table Schema
                  </ThemeButton>
                </> :
                <>
                  <input ref={fileInputRef} type="file" accept=".csv, .xlsx, .xls" onChange={handleSelectFile} hidden />
                  <ThemeButton
                    variant="outline-primary" style={{
                      marginLeft: "10px", marginRight: "10px", marginTop: "10px",
                      fontSize: isMobile ? "14px" : ""
                    }}
                    onClick={handleChooseFile}>
                    Upload a Sample file
                  </ThemeButton>
                </>
              }
            </Col>
          </Row>
          {selectedFile && (
            <div className="d-flex align-items-center mb-3">
              <p className="text-muted" style={{ marginBottom: "0" }}>Selected File: {fileName}</p>
              <ThemeButton
                variant="outline-primary" style={{ marginLeft: "10px", fontSize: isMobile ? "8px" : "12px" }}
                onClick={() => { setSelectedFile(null); setFileName(null); }}>
                Reset/Select another file
              </ThemeButton>
            </div>
          )}
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Table Schema Name</Form.Label>
                <Form.Control
                  type="text"
                  value={tableSchema.table_name}
                  onChange={handleTableNameChange}
                  placeholder="Enter table name"
                  required
                  className="form-control-lg"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={tableSchema.description}
                  onChange={handleDescriptionChange}
                  placeholder="Describe the table schema"
                  required
                  className="form-control-lg"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">Columns</h5>
            <ThemeButton variant="outline-primary" onClick={addColumn} className="d-flex align-items-center">
              <FaPlus className="me-2" /> Add Column
            </ThemeButton>
          </div>
          {tableSchema.columns.map((column, index) => (
            <React.Fragment key={index}>
              <Row
                className={`align-items-center mb-1 p-2 rounded transition-all
                ${hoveredColumnIndex === index ? "bg-light shadow-sm" : "hover-bg-light"}`}
                onMouseEnter={() => setHoveredColumnIndex(index)}
                onMouseLeave={() => setHoveredColumnIndex(null)}
              >
                <Col>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={column.column_name}
                      onChange={(e) => handleColumnChange(index, "column_name", e.target.value)}
                      placeholder="Column name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={column.column_type}
                      onChange={(e) => handleColumnChange(index, "column_type", e.target.value)}
                      required
                    >
                      <option value="">Select Type</option>
                      {globalContstant.datascribeConstants.SUPPORTED_SQL_DATA_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {renderTypeOptions(column, index)}

                <Col className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="Primary Key"
                    checked={column.primary_key}
                    onChange={(e) => handleColumnChange(index, "primary_key", e.target.checked)}
                  />
                </Col>
                <Col className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="Nullable"
                    checked={column.nullable}
                    onChange={(e) => handleColumnChange(index, "nullable", e.target.checked)}
                  />
                </Col>
                <Col className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="Add Metadata"
                    checked={metadataColumns.has(index)}
                    onChange={(e) => handleMetadataToggle(index, e.target.checked)}
                  />
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <ThemeButton
                    variant="outline-primary"
                    onClick={() => removeColumn(index)}
                    className="d-flex align-items-center"
                  >
                    <FaTrash />
                  </ThemeButton>
                </Col>
              </Row>

              {/* Render default value input for non-nullable columns in a separate row */}
              {renderDefaultValueInput(column, index) && (
                <Row className="mb-3 p-2 bg-light rounded">
                  {renderDefaultValueInput(column, index)}
                </Row>
              )}

              {metadataColumns.has(index) && (
                <Row className="mb-3 p-2 bg-light rounded">
                  {renderMetadata(column, index)}
                </Row>
              )}
            </React.Fragment>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        {!isMobile &&
          <div style={{ marginRight: "auto" }}>
            <p className="text-center" style={{ marginBottom: "0", marginTop: "0" }}>Created By: {profile?.first_name + " " + profile?.last_name} </p>
            <p style={{ fontSize: "12px", color: "gray", margin: "0" }}>({profile?.email})</p>
          </div>
        }
        <ThemeButton variant="outline-primary" onClick={handleClose}>
          Cancel
        </ThemeButton>
        <ThemeButton variant="outline-primary" onClick={handleSubmit}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaRegSquarePlus />
            {isMobile ? "" : "Save Schema"}
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTableSchemaModal;