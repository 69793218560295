import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { getTableSchemaById } from "../apis/dataApi";
import ThemeButton from "../components/themeButton";

const ViewTableSchemaModal = ({ show, onHide, tableSchemaId }) => {
  const [tableSchema, setTableSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTableSchema = async () => {
      if (show && tableSchemaId) {
        try {
          setIsLoading(true);
          const response = await getTableSchemaById(tableSchemaId);

          if (response.status >= 200 && response.status < 300) {
            setTableSchema(response.data);
            setError(null);
          } else {
            setError(`Error: ${response.status} - Unable to fetch table schema`);
          }
        } catch (err) {
          setError(`Error: ${err.message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTableSchema();
  }, [show, tableSchemaId]);

  const renderTypeOptionsDetails = (column) => {
    if (!column.type_options) return null;

    const optionEntries = Object.entries(column.type_options);
    return optionEntries.map(([key, value]) => (
      `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`
    )).join(", ");
  };

  const handleClose = () => {
    onHide();
  };

  if (isLoading) {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Loading Table Schema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error Fetching Table Schema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger">{error}</p>
        </Modal.Body>
        <Modal.Footer>
          <ThemeButton variant="outline-primary" onClick={handleClose}>
            Close
          </ThemeButton>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-dialog-centered modal-lg"
      className="custom-modal-style"
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="w-100 text-center">
          View Table Schema
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Table Schema Name</Form.Label>
              <Form.Control
                type="text"
                value={tableSchema.name}
                readOnly
                className="form-control-lg"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={tableSchema.description}
                readOnly
                className="form-control-lg"
              />
            </Form.Group>
          </Col>
        </Row>

        <h5 className="mb-3">Columns</h5>

        {tableSchema.table_schema.columns.map((column, index) => (
          <>
            <Row
              key={index}
              className="align-items-center mb-3 p-2 rounded"
            >
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={column.column_name}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    value={column.column_type}
                    readOnly
                  />
                </Form.Group>
              </Col>

              {column.type_options && (
                <Col>
                  <Form.Group>
                    <Form.Label>Type Options</Form.Label>
                    <Form.Control
                      type="text"
                      value={renderTypeOptionsDetails(column) || "N/A"}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              )}

              {column.default_value !== undefined && (
                <Col>
                  <Form.Group>
                    <Form.Label>Default Value</Form.Label>
                    <Form.Control
                      type="text"
                      value={column.default_value === null ? "NULL" : column.default_value}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              )}

              <Col className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Primary Key"
                  checked={column.primary_key}
                  disabled
                />
              </Col>
              <Col className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Nullable"
                  checked={column.nullable}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Metadata</p>
                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold", fontSize: "14px" }}>Units</Form.Label>
                  <Form.Control
                    type="text"
                    value={column.metadata?.units || "N/A"}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ))}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <ThemeButton variant="outline-primary" onClick={handleClose}>
          Close
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewTableSchemaModal;