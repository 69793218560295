import React, { useState, useEffect } from "react";
import Logout from "./logout";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { FaBars, FaBarsStaggered } from "react-icons/fa6";
import DataScribeLogoSmallCloud from "../assets/images/DataScribeLogoSmallCloud.png";
import { switchActiveClient } from "../apis/userApis";

const StyledNavbar = styled(Navbar)`
  background-color: #50011a;
  color: white;
  height: 95px;
  margin: 5px 5px 5px 5px;
  border-radius: 7px;
  font-size: 20px;

  .navbar-brand {
    margin-left: 25px;
    padding: 10px;
    color: white;
  }

  .nav-link {
    color: white;
    transition: all 0.3s ease-in-out;
    position: relative;

    &:hover,
    &.active {
      color: #fff;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: scaleX(1);
        transition: transform 0.3s ease-in-out;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  .navbar-toggler {
    color: white;
    border: none;
    transition: all 0.3s ease-in-out;
  }

  .navbar-toggler.open {
    transform: rotate(90deg);
  }

  @media (max-width: 1250px) {
    height: auto;

  .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: auto;
    z-index: 1000;
  }

  .dropdown-item {
    &:hover, &:focus {
      background-color: #6b0024;
    }
  }

    .navbar-collapse {
      background-color: #50011a;
      padding: 10px;
    }

    .nav-link {
      padding: 10px 0;
    }

    .navbar-nav {
      align-items: start;
    }
  }
`;

function ThemeNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [activeClient, setActiveClient] = useState(null);
  const [isDatascribeManager, setIsDatascribeManager] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1250);
  const navigate = useNavigate();

  const toggle = () => setIsOpen(!isOpen);
  const toggleNavbar = () => setIsNavbarExpanded(!isNavbarExpanded);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1250);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.active_client_id) {
      const activeClient = userData.clients.find(client => client.id === userData.active_client_id);
      setActiveClient(activeClient);
    }
    if (userData && userData.datascribe_manager) {
      setIsDatascribeManager(true);
    }
  }, []);

  let userData = JSON.parse(localStorage.getItem("user"));
  if (!userData) {
    userData = { name: "Guest", picture: "", clients: [] };
  }

  const handleClientChange = async (client) => {
    const response = await switchActiveClient(client.id);
    if (response.status >= 200 && response.status < 300) {
      setActiveClient(client);
      // console.log( JSON.stringify(response.data.userInfo));
      localStorage.setItem("user", JSON.stringify(response.data.userInfo));
      window.location.reload();
    }
    else {
      alert("Failed to switch client. Error: " + response.status + "\n" + response.data.message);
    }
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToDatascribeManager = () => {
    navigate("/datascribeManagment");
  };

  return (
    <StyledNavbar expand={isMobile ? false : "lg"}>
      <Navbar.Brand href="/home" style={{ fontSize: isMobile ? "20px" : "25px", marginLeft: "0" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img src={DataScribeLogoSmallCloud} alt="DataScribe Logo" style={{ height: isMobile ? "30px" : "50px" }} />
          Datascribe.Cloud
        </div>
      </Navbar.Brand>
      <Navbar.Toggle style={{ marginRight: "10px", "--bs-navbar-toggler-focus-width": "0" }} aria-controls="basic-navbar-nav" toggle={isNavbarExpanded.toString()} onClick={toggleNavbar}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {isNavbarExpanded ? <FaBarsStaggered /> : <FaBars />}
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/home">Home</Nav.Link>
          <Nav.Link href="/folders">Data Structures</Nav.Link>
          <Nav.Link href="/forms">Data Travellers</Nav.Link>
          <Nav.Link href="/responses">Data Dispatch</Nav.Link>
          <Nav.Link href="/analysis">Data Analysis</Nav.Link>
        </Nav>
        <Nav style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <div style={{ padding: isMobile ? "20px 0" : "0 10px 0 0" }}>
            <p style={{ marginBottom: "0rem" }}>{userData.email}</p>
          </div>
          <div style={{ padding: isMobile ? "10px 0" : "0 25px 0 0" }}>
            <Dropdown isopen={isOpen.toString()} toggle={toggle.toString()} align={{ lg: "end" }} >
              <Dropdown.Toggle variant="transparent">
                <Avatar name={`${userData.first_name} ${userData.last_name}`} size="50" src={userData.profile_picture} round />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item disabled>
                  Signed in as {`${userData.first_name} ${userData.last_name}`}
                </Dropdown.Item>
                <Dropdown.Item onClick={goToProfile} style={{
                  "--bs-dropdown-link-active-bg": "#50011a",
                }}>
                  Profile/Administration
                </Dropdown.Item>
                <Dropdown.Divider />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Dropdown isOpen={isClientDropdownOpen} toggle={() => setIsClientDropdownOpen(!isClientDropdownOpen)}>
                    <Dropdown.Toggle style={{
                      "--bs-btn-bg": "#fff",
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                      "--bs-btn-disabled-color": "#50011a",
                      "--bs-btn-disabled-bg": "transparent",
                      "--bs-btn-disabled-border-color": "#50011a",
                      fontSize: isMobile ? "12px" : "16px",
                    }}>
                      {activeClient ? `Organization: ${activeClient.name}` : "Select Client"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {userData.clients.map((client) => (
                        <Dropdown.Item
                          style={{
                            "--bs-dropdown-link-active-bg": "#50011a",
                          }}
                          key={client.id}
                          onClick={() => handleClientChange(client)}
                          active={activeClient && activeClient.id === client.id}
                        >
                          {client.name} ({client.user_role})
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown.Divider />
                {isDatascribeManager && (
                  <>
                    <Dropdown.Item onClick={goToDatascribeManager} style={{
                      "--bs-dropdown-link-active-bg": "#50011a",
                    }}>
                      Datascribe Managment
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item style={{
                  "--bs-dropdown-link-active-bg": "transparent",
                  cursor: "default"
                }}>
                  <Logout />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar >
  );
}

export default ThemeNavbar;
