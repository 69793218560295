import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Card, Button, Form, Spinner, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeButton from "./themeButton";
import { parseFileForSQLDataIngestion, postSQLIngestionQuery, getDataFromTable } from "../apis/dataApi";
import { getAllResponses } from "../apis/responseApis";
// import { FaArrowUpRightFromSquare } from "react-icons/fa6";


const BackArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-arrow-left"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"
    />
  </svg>
);

const DataTablesDetailedView = ({ dataTableName, setSelectedDataTableName }) => {
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileMetadata, setSelectedFileMetadata] = useState(null);
  const [fileError, setFileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [parsedDataColumns, setParsedDataColumns] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableDataColumnNumber, setTableDataColumnNumber] = useState(null);
  const [responses, setResponses] = useState([]);
  // const reponseTableRef = useRef(null);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null);
  const observerTarget = useRef(null);

  // const [selectedResponse, setSelectedResponse] = useState(null);

  // const handleSelectResponse = (responseId) => {
  //   setSelectedResponse(responseId === selectedResponse ? null : responseId);
  // };

  // const handleInjectResponse = () => {
  //   // Placeholder for future functionality
  //   console.log("Injecting response:", selectedResponse);
  // };

  const fetchResponses = useCallback((pageNumber = 0) => {
    if (!hasMore || isTableLoading) return;

    setIsTableLoading(true);
    getAllResponses(pageNumber)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setResponses((prevResponses) => {
            if (pageNumber === 0) return response.data.responses;

            const newResponses = response.data.responses;
            const uniqueNewResponses = newResponses.filter(
              (newResponse) => !prevResponses.some((prevResponse) => prevResponse.id === newResponse.id)
            );
            return [...prevResponses, ...uniqueNewResponses];
          });
          setHasMore(response.data.pagination.total_pages > pageNumber + 1);
          setPage(pageNumber);

          console.log(responses);
        } else {
          alert("Error: Unable to fetch responses. Please try again.\nServer Response: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Error: Unable to fetch responses. Please try again.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  }, [hasMore, isTableLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isTableLoading) {
          fetchResponses(page);
        }
      },
      {
        root: tableContainerRef.current,
        threshold: 0.1,
        rootMargin: "20px",
      }
    );

    const currentObserverTarget = observerTarget.current;
    if (currentObserverTarget) {
      observer.observe(currentObserverTarget);
    }

    return () => {
      if (currentObserverTarget) {
        observer.unobserve(currentObserverTarget);
      }
    };
  }, [fetchResponses, hasMore, isTableLoading, page]);


  const handleBackButton = () => {
    setSelectedDataTableName(null);
    // reset state variables
    setSelectedFile(null);
    setSelectedFileMetadata(null);
    setFileError("");
    setIsLoading(false);
    setParsedData(null);
    setTableData(null);
    setResponses([]);
    setPage(0);
    setHasMore(true);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFileError("");

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setFileError("File size exceeds 50MB limit");
        setSelectedFile(null);
        event.target.value = null;
      } else {
        setSelectedFileMetadata(file);
        const fileChunk = await file.arrayBuffer();
        setSelectedFile(fileChunk);
      }
    }
  };

  const handleFileUpload = async () => {
    setIsLoading(true);
    try {
      const response = await parseFileForSQLDataIngestion(selectedFile, selectedFileMetadata, dataTableName);
      if (response.status === 200) {
        // console.log(response.data.data);
        // console.log(response.data.columnDef);
        setParsedData(response.data.data);
        setParsedDataColumns(response.data.columnDef);
      } else {
        alert("Error: Unable to generate insert sql queries. Please try again.\nServer Response: " + response.data.message);
      }
    } catch (err) {
      alert("Error: Unable to generate insert sql queries. Please try again.");
    }
    setIsLoading(false);
  };

  const handleInjectData = async () => {
    setIsLoading(true);
    try {
      const response = await postSQLIngestionQuery(parsedData, dataTableName);
      if (response.status === 200) {
        alert("Data ingestion successful!");
        fetchTableData();
        setParsedData(null);
      } else {
        alert("Error: Unable to ingest data. Please try again.\nServer Response: " + response.data.message);
      }
    } catch (err) {
      alert("Error: Unable to ingest data. Please try again.");
    }
    setIsLoading(false);
  };

  const fetchTableData = async () => {
    try {
      const response = await getDataFromTable(dataTableName);
      if (response.status === 200) {
        // console.log(response.data);
        console.log(Object.keys(response.data).length);
        setTableData(response.data);
        setTableDataColumnNumber(Object.keys(response.data).length);
      } else {
        alert("Error: Unable to fetch table data. Please try again.\nServer Response: " + response.data.message);
        setSelectedDataTableName(null);
      }
    } catch (err) {
      alert("Error: Unable to fetch table data. Please try again.");
      setSelectedDataTableName(null);
    }
  };

  const handleLoadMoreTableData = async () => {
    // console.log("Load more table data");
    try {
      const response = await getDataFromTable(dataTableName, tableDataColumnNumber, 10);
      if (response.status === 200) {
        // console.log(response.data);
        setTableDataColumnNumber(tableDataColumnNumber + Object.keys(response.data).length);
        setTableData((prevData) => [...prevData, ...response.data]);
      } else {
        alert("Error: Unable to fetch table data. Please try again.\nServer Response: " + response.data.message);
      }
    } catch (err) {
      alert("Error: Unable to fetch table data. Please try again.");
    }
  };

  useEffect(() => {
    console.log("dataTableName:", dataTableName);
    setIsLoading(true);
    fetchTableData();
    setIsLoading(false);

  }, [dataTableName]);

  return (
    <Container fluid className="p-4">
      <Button
        variant="danger"
        className="mb-4 d-flex align-items-center"
        onClick={handleBackButton}
      >
        <BackArrowIcon />
        <span className="ms-2">Back</span>
      </Button>

      <div>
        <h1 className="mb-4">{dataTableName}</h1>

        {isLoading && (
          <div className="d-flex justify-content-end">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>

      <Row className="g-4 h-100">
        {/* Import Card - 40% width */}
        <Col xs={12} md={5} className="d-flex">
          <Card className="w-100" style={{ borderColor: "#50011a" }}>
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-4">Import from Computer</Card.Title>
              <div className="text-center flex-grow-1 d-flex flex-column justify-content-center">
                <div className="border border-2 border-dashed rounded p-4 mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                    fill="currentColor" className="bi bi-upload text-secondary mb-3"
                    viewBox="0 0 16 16" >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                  </svg>
                  <p className="text-secondary mb-3">
                    Upload CSV or Excel files
                    <br />
                    <small className="text-muted">(Maximum file size: 50MB)</small>
                  </p>
                  <Form.Control
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileChange}
                    style={{ height: "2.5rem" }}
                  />
                  {fileError && (
                    <p className="text-danger mt-2 small">
                      {fileError}
                    </p>
                  )}
                </div>
                <ThemeButton
                  variant="outline-primary"
                  className="w-100"
                  onClick={handleFileUpload}
                  disabled={!selectedFile || fileError}
                >
                  Parse File
                </ThemeButton>
                {selectedFile && (
                  <p className="text-secondary mt-2 small">
                    Selected: {selectedFileMetadata.name}
                  </p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Import from Drive Card - 60% width */}
        <Col xs={12} md={7} className="d-flex">
          <Card className="w-100" style={{ borderColor: "#50011a" }}>
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-4">Import from Drive</Card.Title>
              {/* <div className="table-container"
                ref={reponseTableRef}
                style={{
                  width: "100%",
                  overflowX: "auto",
                  maxHeight: "400px",
                  overflowY: "auto"
                }}>
                <Table striped bordered className="curved-table" style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#50011a" }}>
                    <tr>
                      <th style={{ width: "10%", color: "white", padding: "15px" }}>#</th>
                      <th style={{ width: "20%", color: "white", padding: "15px" }}>File ID</th>
                      <th style={{ width: "20%", color: "white", padding: "15px" }}>Location</th>
                      <th style={{ width: "20%", color: "white", padding: "15px" }}>File Name</th>
                      <th style={{ width: "30%", color: "white", padding: "15px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "16px", backgroundColor: "white" }}>
                    {responses.map((response, index) => (
                      <tr key={response.id}>
                        <td style={{ textAlign: "center", padding: "12px" }}>{index + 1}</td>
                        <td style={{ textAlign: "center", padding: "12px" }}>{response.id}</td>
                        <td style={{ textAlign: "center", padding: "12px" }}>{response.folder_path}</td>
                        <td style={{ textAlign: "center", padding: "12px" }}>
                          {response.document_title}
                          <ThemeButton
                            variant="outline-primary"
                            style={{
                              marginLeft: "5px",
                              fontSize: "12px",
                              padding: "2px 5px"
                            }}
                            onClick={() => window.open(`https://drive.google.com/drive/u/0/folders/${response.folder_google_id}`, "_blank")}
                          >
                            <FaArrowUpRightFromSquare />
                          </ThemeButton>
                        </td>
                        <td style={{ textAlign: "center", padding: "12px" }}>
                          <div className="d-flex gap-2 justify-content-center">
                            <ThemeButton style ={{"--bs-btn-bg": selectedResponse === response.id ? "#e5bdbd" : "#transparent" }}
                              variant={selectedResponse === response.id ? "primary" : "outline-primary"}
                              onClick={() => handleSelectResponse(response.id)}
                            >
                              Select
                            </ThemeButton>
                            <ThemeButton
                              variant="outline-primary"
                              disabled={selectedResponse !== response.id}
                              onClick={handleInjectResponse}
                            >
                              Inject
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div ref={observerTarget} style={{ height: "40px", textAlign: "center", padding: "10px" }}>
                  {isTableLoading && (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </div>
              </div> */}

              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px", color: "#555", backgroundColor: "#f9f9f9", borderRadius: "8px", padding: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <p style={{ margin: 0 }}>🚧 Under Construction, coming soon. 🚧</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {parsedData && (
        <Row className="g-4 mt-4">
          <Col xs={12} md={12}>
            <Card className="w-100" style={{ borderColor: "#50011a" }}>
              <Card.Body>
                <Card.Title className="mb-4">Parsed Values</Card.Title>
                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", marginBottom: "60px" }}>
                  <Table striped bordered hover className="curved-table">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}>Status</th>
                        <th style={{ width: "40%" }}>Actions</th>
                        {parsedData[0]?.parsed_values.map((_, colIndex) => (
                          <th key={colIndex} style={{ textAlign: "center", whiteSpace: "nowrap", width: "10%" }}>{parsedDataColumns[colIndex].column_name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {parsedData.map((rowData, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div style={{ columnCount: 1, columnGap: 0 }}>
                                {rowData.errors.map((error, index) => (
                                  <div key={index} className="d-flex align-items-center gap-2" style={{ breakInside: "avoid" }}>
                                    <div
                                      className="rounded-pill position-relative"
                                      style={{
                                        width: "12px", height: "12px", flexShrink: 0,
                                        backgroundColor: "#dc3545",  // red
                                      }}
                                    >
                                    </div>
                                    <span style={{ whiteSpace: "nowrap" }}>{error}</span>
                                  </div>
                                ))}
                                {rowData.warnings.map((warning, index) => (
                                  <div key={index} className="d-flex align-items-center gap-2" style={{ breakInside: "avoid" }}>
                                    <div
                                      className="rounded-pill position-relative"
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        flexShrink: 0,
                                        backgroundColor: "#ffc107",  // yellow
                                      }}
                                    >
                                    </div>
                                    <span style={{ whiteSpace: "nowrap" }}>{warning}</span>
                                  </div>
                                ))}
                                {rowData.errors.length === 0 && rowData.warnings.length === 0 && (
                                  <div className="d-flex align-items-center gap-2" style={{ breakInside: "avoid" }}>
                                    <div
                                      className="rounded-pill position-relative"
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        flexShrink: 0,
                                        backgroundColor: "#198754",  // green
                                      }}
                                    >
                                    </div>
                                    <span style={{ whiteSpace: "nowrap" }}>No errors or warnings found</span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <ThemeButton
                                  variant="outline-primary"
                                  onClick={() => {
                                    const newQueries = [...parsedData];
                                    newQueries[index].showSql = !newQueries[index].showSql;
                                    setParsedData(newQueries);
                                  }} style={{ whiteSpace: "nowrap" }}
                                >
                                  {rowData.showSql ? "Hide SQL Query" : "Show SQL Query"}
                                </ThemeButton>
                              </div>
                            </td>
                            {rowData.parsed_values.map((value, colIndex) => (
                              <td key={colIndex}>{value}</td>
                            ))}
                          </tr>
                          {rowData.showSql && (
                            <tr>
                              <td colSpan={rowData.parsed_values.length + 2}>
                                <Form.Control
                                  as="textarea"
                                  value={rowData.sql}
                                  onChange={(e) => {
                                    const newQueries = [...parsedData];
                                    newQueries[index].sql = e.target.value;
                                    setParsedData(newQueries);
                                  }}
                                  className="text-sm font-monospace mt-2"
                                  style={{
                                    fontSize: "0.75rem",
                                    whiteSpace: "nowrap",
                                    overflowX: "auto",
                                    resize: "none",
                                    height: "auto",
                                    lineHeight: "1.5"
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div style={{
                  position: "absolute",
                  bottom: "20px",
                  right: "0",
                  padding: "0 20px",
                  backgroundColor: "white",
                  borderTop: "1px solid #dee2e6",
                  paddingTop: "15px"
                }}>
                  <ThemeButton variant="outline-primary" className="w-100" style={{ marginRight: "10px" }}
                    onClick={() => { setParsedData(null); }}>
                    Stop Injection
                  </ThemeButton>
                  <ThemeButton variant="outline-primary" className="w-100"
                    onClick={handleInjectData} >
                    Inject Data
                  </ThemeButton>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}


      {tableData && (
        <Row className="g-4 mt-4">
          <Col xs={12}>
            <Card className="w-100" style={{ borderColor: "#50011a" }}>
              <Card.Body>
                <Card.Title className="mb-4 d-flex justify-content-between align-items-center">
                  <div>
                    Table Data
                    <p className="text-muted" style={{ fontSize: "0.8rem" }}>Showing rows {tableDataColumnNumber === 0 ? 0 : 1} to {tableDataColumnNumber}</p>
                  </div>
                  <ThemeButton variant="outline-primary" size="sm" onClick={handleLoadMoreTableData}>
                    Load More Data
                  </ThemeButton>
                </Card.Title>
                <div className="table-responsive" style={{ maxHeight: "600px" }}>
                  <table className="table table-hover">
                    <thead style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                      <tr>
                        {Object.keys(tableData[0] || {}).map((header) => (
                          <th key={header} className="text-nowrap px-4 py-3"
                            style={{
                              fontSize: "0.875rem", fontWeight: "600", color: "#444",
                              borderBottom: "2px solid #50011a"
                            }}>
                            {header.replace(/_/g, " ")}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr
                          key={index}
                          style={{
                            transition: "background-color 0.2s",
                            cursor: "pointer"
                          }}
                          className="hover:bg-gray-50"
                        >
                          {Object.values(row).map((value, cellIndex) => (
                            <td
                              key={cellIndex}
                              className="text-nowrap px-4 py-3"
                              style={{
                                fontSize: "0.875rem",
                                color: "#666",
                                borderBottom: "1px solid #eee"
                              }}
                            >
                              {value === null ? "-" : value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DataTablesDetailedView;