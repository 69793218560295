import React from "react";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "@jsonforms/material-renderers";

const JsonFromPreview = ({ schema, uischema, fileUploadSchema, formStructure }) => {

  return (
    <div className="container mt-5">
      {schema && uischema && (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <h3>{formStructure.title}</h3>
                <p>{formStructure.description}</p>
              </div>
              <div className="card-body">
                <JsonForms
                  schema={schema}
                  uischema={uischema}
                  renderers={materialRenderers}
                />

                {fileUploadSchema && fileUploadSchema.length > 0 && (
                  <>
                    {fileUploadSchema.map((fileUpload, index) => (
                      <div className="mt-4" key={index}>
                        <label className="form-label" htmlFor={`customFile-${index}`}>
                          {fileUpload.title}
                          <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            Allowed file types: {fileUpload.fileTypes.join(", ")}
                          </p>
                        </label>
                        <input type="file" className="form-control" id={`customFile-${index}`}
                          accept={fileUpload.fileTypes.join(", ")} multiple />
                        {fileUpload.isRequired && <p className="text-danger" style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>Required</p>}
                      </div>
                    ))}
                  </>
                )}
                <div className="mt-4">
                  <button type="button" className="btn btn-primary" disabled={true}>
                    {"Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JsonFromPreview;